#allnetFlatVergleich {
	.adapt-width .col-md-6,
	.adapt-width .col-md-8,
	.adapt-width .col-md-4 {
		width: 100%;
	}
	.adapt-width h1,
	.adapt-width .col-md-1 {
		display:none;
	}
	.adapt-width .btn {
		margin-bottom: 10px;
	}
	.adapt-width div {
		min-height: 0 !important;
	}
	.adapt-width .remove {
		line-height: 45px;
		color: #000;
		font-size: 20px;
		cursor: pointer;
	}
	.adapt-hide {
		display:none;
	}
}
.modal  {
	color: @color;

	.transparent {
		background: transparent;
		border: 0;
		padding-bottom: 0px;

		button.close {
			font-size: 40px;
			line-height: 40px;
			margin-top: -10px;
			margin-right: -15px;
			padding: 0px 15px;
		}
	}	
}
.modal-lg {
	color: @color;
	max-width: 992px;
	.modal-header {
		&.gradient {
			.modal-title {
				color: #fff;
			}	
			.statistics {
				display: inline;
				font-size: 14px;
			}
		}	
		a.back {
			font-size: 20px;
			font-weight: normal;
			display: inline-block;
			margin-top: 6px;
			margin-right: -15px;
			padding: 0px;
			color: #fff;
			opacity: 0.9;
		}		
		.btn {
			margin-top: 0px;
		}
	}
	.left-wrap .benefits .item-left {
		width: 180px !important;
		white-space: normal !important;
	}
	.left-wrap .benefits .item-right {
		width: 60px !important;
	}
}
.modal-xl.modal-lg {
	width: 1200px !important;
	max-width: none;
}

