.popover {
	max-width:100%;
	font-weight:normal !important;
	color:#444;	
	strong, b {

		font-size: 15px;
	}
	h3 {
		padding: 8px 14px;
		border-bottom: 1px solid #ebebeb;
		-webkit-border-radius: 5px 5px 0 0;
		-moz-border-radius: 5px 5px 0 0;
		border-radius: 5px 5px 0 0;
		background: #aa334c!important;
		color: #fff;
		margin: 0;
		border: none;
		font-size:13px !important;
		font-weight:bold;
	}
}