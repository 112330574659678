/**
	
	HINWEIS:
	- In dieser Datei werden Änderungen zu allen "Datatable" Tabellen vorgenommen, wenn der Client eine Seitegröße von 992px unterschreitet
	- Hier werden üblicherweise alle Tarife in tabellenform formatiert für mobile Endgeräte optimiert

 */

/*===============================================================================
=            Manueller Umbruch der Tabelle für z.B zu schmale Seiten            =
===============================================================================*/

@media all and (min-width: 768px) {
	.table-break {
		.hidden-xs, .hidden-sm{
			display: none;
		}
		.hidden-md, .hidden-lg{
			display: inline !important;
		}
		table {
			min-width: 310px;
			&.dt {
				font-size: 12px;
				display: block;
				thead {
					display: block;
					tr {
						border: 1px #ccc solid;
						margin-top: 3px;
						margin-bottom: 3px;

						display: table-row;
						display: flex;
						flex-flow: row wrap;
						align-items: center;
						justify-content: center;
						th {
							display: block;
							font-size:18px !important;
							line-height:25px;				    	
							flex: 1 0 31%;

							&:nth-child(1) {
								flex: 0 0 240px;
							}
							&:nth-child(2) {
								display: none;
							}    
							&:nth-child(3) {
								display: none;
							}    	
							&:nth-child(5) {
								border-top-right-radius: 3px;
								border-bottom-right-radius: 3px;
							}	
							&:nth-last-child(-n+2) {
								display: none;
							}											    				    		        
						}
					}
				}
				tbody {
					display: block;
					tr {
						display: flex;
						flex-flow: row wrap;
						align-items: center;
						justify-content: center;
						position: relative;
						border-bottom: 1px @tarif-border-color solid;
						td {
							display: block;
							padding: 0;
							flex: 1 0 31%;
							border: 0;			    	

							&:nth-child(1) {
								flex: 0 0 240px;
								align-self: flex-start;
								a img {
									margin-top: 45px;
								}
							}
							&:nth-child(2) {
								position: absolute;
								top: 92px;
								left: 70px;
								width: 48px;
								height: 50px;
								padding: 0px;
								border: 1px @tarif-border-color solid;
								box-shadow: 1px 1px 2px @tarif-border-color;
								border-radius: 6px;
								background: #ffffff; 
								background: #ff0000;

								strong {
									font-size:18px;
									display:block;
									margin-top: 5px;
								}	
								span {
									font-size: 11px;
								}	
								br {
									display: none;
								}					
							}	
							&:nth-child(3) {
								backgorund: red;
								position: absolute;
								top: 92px;
								left: 124px;
								width: 48px;
								height: 50px;
								padding: 0px;
								border: 1px @tarif-border-color solid;
								box-shadow: 1px 1px 2px @tarif-border-color;
								border-radius: 6px;
								background: #ffffff; 	

								img {
									width: auto;
									height: 21px;
									margin-top: 7px;
									display: inline-block;
								}				        
							}  
							&:nth-child(4) {
								border-left: 1px @tarif-border-color solid;
								border-right: 1px @tarif-border-color solid;
							}					    
							&:last-child {
								flex-basis: 71%;
							}  
							.charge {
								max-width: 200px;
								margin: 0 auto;
								font-size: 14px;
								strong {
									font-size: 32px;
									font-weight: @weight-semi-bold;
									color: @primary-color;
								}
								span {
									font-size: 22px;
									font-weight: @weight-normal;
								}
								hr {
									max-width: 95%;
									border-color: #bfbfbf;
									margin-top: 5px;
									margin-bottom: 5px;
								}	
							}
							.benefits {
								width: 95%;
								min-width: 90px;
								.item {
									font-size: 14px;
									.item-left {
										width: 40%;
									}								
									.item-right {
										width: 60%;
									}
								}							
							}
							.specials {
								font-size: 14px;
								padding: 10px;
							}	
							.btn-lg {
								display: block;
								padding-top: 5px;
								padding-bottom: 5px;
								margin: 5px;
							}
							.hide {
								display: inline;
							}																		 
						} 
					}
				}			
			}
		}
	}
}
/*======================================================
=            große Handys bishin zu Tablets            =
======================================================*/
@media all and (max-width: 992px) {
	table {
		min-width: 310px;
		&.dt {
			font-size: 12px;
			display: block;
			thead {
				display: block;
				tr {
					display: table-row;
			        display: flex;
			        flex-flow: row wrap;
			        align-items: center;
			        justify-content: center;
				    th {
				    	display: block;
						font-size:12px !important;
						line-height:25px;				    	
				    	flex: 1 0 31%;

					    &:nth-child(1) {
					   		flex: 0 0 240px;
					    }
					    &:nth-child(2) {
					    	display: none;
					    }    
					    &:nth-child(3) {
					    	display: none;
					    }    	
						&:nth-child(5) {
							border-top-right-radius: 3px;
							border-bottom-right-radius: 3px;
						}	
					    &:nth-last-child(-n+2) {
					    	display: none;
					    }											    				    		        
				    }
				}
			}
			tbody {
				display: block;
			    tr {
					border: 1px #aaa solid;

			    	margin: 15px 0px;
			    	//box-shadow: 1px 1px 2px 0px rgba(153, 153, 153, 0.58);
			    	//background: #ff0000;
			    	padding: 5px;

			        display: flex;
			        flex-flow: row wrap;
			        align-items: center;
			        justify-content: center;
			        position: relative;
			        
					&:nth-child(n) {
						background: #F8F8F8;
					}
					&:nth-child(2n) {
						background: #F8F8F8;
					}
				    td {
				    	display: block;
				    	padding: 0;
				    	flex: 1 0 31%;
						border: 0;			    	

						.rating_bar_klein {
							margin: 0 auto;
							margin-top: 60px;
						}
				        &:nth-child(1) {
				        	flex: 0 0 240px;
				        	align-self: flex-start;
				        	a img {
				        		margin-top: 10px;
				        		width: 80px;
				        		height: auto;
				        	}
				        }
						&:nth-child(2) {
					        position: absolute;
					        top: 57px;
					        left: 70px;
					        width: 80px;
							height: 20px;
							padding: 0px;
							padding-right: 5px;

							text-align: right;

							strong {
							    font-size: 14px;
							    margin-top: 5px;
							    font-weight: @weight-normal;
							}
							span {	
								font-size: 14px;										
								&.prepaid {
									margin-top: 18px;
									font-size: 11px;
									font-weight: @weight-normal;
									color: @primary-color;											
								}	
								&.prepaid_30 {
						    		strong {
										font-size: 11px;
										font-weight: @weight-normal;
									}										
									margin-top: 5px;
									font-size: 11px;
									font-weight: @weight-normal;
									color: @primary-color;										
								}	
								&.prepaid_1 {
									display: inline;
									margin-top: 12px;
									font-size: 15px;
									font-weight: @weight-bold;
									color: @primary-color;									
								}																	
							}	
							br {
								display: none;
							}														
						}	
						&:nth-child(3) {
					        position: absolute;
					        top: 74px;
					        left: 70px;
					        width: 80px;
					        height: 40px;
							padding: 0px; 	
							padding-right: 5px;
							text-align: right;

							img {
								width: auto;
								height: 21px;
								margin-top: 7px;
								display: inline;
							}				   
							br {
								display: none;
							}     
					    } 			        
						/*&:nth-child(2) {
					        position: absolute;
					        top: 57px;
					        left: 70px;
					        width: 48px;
							height: 50px;
							padding: 0px;
							border: 1px @tarif-border-color solid;
							box-shadow: 1px 1px 2px @tarif-border-color;
							border-radius: 6px;

							background: #ffffff; 

							strong {
							    font-size: 18px;
							    display: block;
							    margin-top: 5px;
							    font-weight: @weight-normal;
							}
							span {
								display:block;	
								font-size: 11px;										
								&.prepaid {
									margin-top: 18px;
									font-size: 11px;
									font-weight: @weight-normal;
									color: @primary-color;											
								}	
								&.prepaid_30 {
						    		strong {
										font-size: 11px;
										font-weight: @weight-normal;
									}										
									margin-top: 5px;
									font-size: 11px;
									font-weight: @weight-normal;
									color: @primary-color;										
								}	
								&.prepaid_1 {
									margin-top: 12px;
									font-size: 18px;
									font-weight: @weight-bold;
									color: @primary-color;									
								}																	
							}															
						}	
						&:nth-child(3) {
					        backgorund: red;
					        position: absolute;
					        top: 57px;
					        left: 124px;
					        width: 48px;
					        height: 50px;
							padding: 0px;
							border: 1px @tarif-border-color solid;
							box-shadow: 1px 1px 2px @tarif-border-color;
							border-radius: 6px;
							background: #ffffff; 	

							img {
								width: auto;
								height: 21px;
								margin-top: 7px;
								display: inline-block;
							}				        
					    } 
					    */ 
						&:nth-child(4) {
							border-left: 1px @tarif-border-color solid;
							border-right: 1px @tarif-border-color solid;
						}					    
						&:last-child {
							flex-basis: 71%;
						}  
						.charge {
							max-width: 150px;
							margin: 0 auto;
							font-size: 13px;
							strong {
								font-size: 26px;
								font-weight: @weight-normal;
								color: @primary-color;
								letter-spacing: 0.0005em;
							}
							span {
								font-size: 13px;
								font-weight: @weight-normal;
							}
							hr {
								max-width: 95%;
								border-color: #bfbfbf;
								margin-top: 5px;
								margin-bottom: 5px;
							}	
						}
						.benefits {
							width: 95%;
							min-width: 90px;
							.item {
								.item-left {
									width: 40%;
								}								
								.item-right {
									width: 60%;
								}
							}							
						}
						.specials {
							padding: 10px;
						}	
						.btn-lg {
							display: block;
							padding-top: 5px;
							padding-bottom: 5px;
							margin: 5px;
							//color: @primary-color;
							font-weight: @weight-bold;
							//background: #fff;
							font-size: 17px;							
						}	
						.btn-sm {
							margin: 5px;
							padding-top: 7px;
							padding-bottom: 7px;
							border-radius: 6px;
							border: 1px #aaa solid;
						}											
						.hide {
							display: inline;
						}																		 
				    } 
			    }
			}			
		}
		&.dt-phone {
			font-size: 12px;
			display: block;
			thead {
				display: block;
				tr {
					display: table-row;
			        display: flex;
			        flex-flow: row wrap;
			        align-items: center;
			        justify-content: center;
				    th {
				    	display: block;
						font-size:12px !important;
						line-height:25px;				    	
				    	flex: 1 0 31%;

					    &:nth-child(1) {
					   		display: none;
					    }
					    &:nth-child(2) {
							flex: 0 0 240px;
					    }    	
						&:nth-child(5) {
							border-top-right-radius: 3px;
							border-bottom-right-radius: 3px;
						}	
					    &:nth-last-child(-n+2) {
					    	display: none;
					    }											    				    		        
				    }
				}
			}
			tbody {
				display: block;
			    tr {
					border: 2px #bbb solid;
			    	border-left: none;
			    	border-right: none;
			    	border-bottom: none;
			    	margin: 10px 0px;

			        display: flex;
			        flex-flow: row wrap;
			        align-items: center;
			        justify-content: center;
			        position: relative;

					&:nth-child(n) {
						background: #F8F8F8;
					}
					&:nth-child(2n) {
						background: #F8F8F8;
					}
				    td {
				    	display: block;
				    	padding: 0;
				    	flex: 1 0 31%;
						border: 0;			    	

				        &:nth-child(1) {
				        	display: none;
				        }
						&:nth-child(2) {
				        	flex: 0 0 200px;
				        	align-self: flex-start;		
				        	padding: 10px 0px;
						}	
						&:nth-child(3) {
							border-left: 1px @tarif-border-color solid;
							border-right: 1px @tarif-border-color solid;
						}											    
						&:last-child {
							flex-basis: 71%;
						}  
						.charge {
							max-width: 150px;
							margin: 0 auto;
							font-size: 13px;
							strong {
								font-size: 26px;
								font-weight: @weight-semi-bold;
								color: @primary-color;
							}
							span {
								font-size: 13px;
								font-weight: @weight-normal;
							}
							hr {
								max-width: 95%;
								border-color: #bfbfbf;
								margin-top: 5px;
								margin-bottom: 5px;
							}	
						}
						.benefits {
							width: 95%;
							min-width: 90px;
							.item {
								.item-left {
									width: 40%;
								}								
								.item-right {
									width: 60%;
								}
							}							
						}
						.specials {
							padding: 10px;
						}	
						.btn-lg {
							display: block;
							padding-top: 5px;
							padding-bottom: 5px;
							margin: 5px;
							//color: @primary-color;
							font-weight: @weight-bold;
							//background: #fff;
							font-size: 17px;							
						}
						.btn-sm {
							margin: 5px;
							padding-top: 7px;
							padding-bottom: 7px;
							border-radius: 6px;
							border: 1px #aaa solid;
						}							
						.hide {
							display: inline;
						}																		 
				    } 
			    }
			}			
		}
	}	
}  
/*=================================================
=            typische Größe für Handys            =
=================================================*/
@media all and (max-width: 768px) {

	.dataTables_wrapper {
		.dataTables_length {
		}
		> div:nth-child(3) .col-sm-5 {
		}
		> div:nth-child(3) .col-sm-7 {
		}	
		.dataTables_paginate {
			.pagination {
				> li {
					> a {
						padding: 6px;
						font-size: 12px;
					}
					&:first-child {
						display: none;
					}
				}
			}		
		}			
	}

	table {
		&.dt {
			thead {
				tr {
				    th {		    	
				    	flex: 1 0 31%;

					    &:nth-child(1) {
					   		flex: 0 0 105px;
					    }										    				    		        
				    }
				}
			}
			tbody {
			    tr {
				    td {
				    	flex: 1 0 31%;	   

				        &:nth-child(1) {
				        	flex: 0 0 105px;
				        }		
						&:nth-child(2) {
					        left: 10px;				
						}	
						&:nth-child(3) {
					        left: 10px;			        
					    } 
						.charge {
							b {
								display: block;
							}
						}
						
						.btn-sm {
							margin: 5px;
							padding-top: 12px;
							padding-bottom: 12px;
							border-radius: 6px;
							border: 1px #aaa solid;
						}												
				    } 
			    }
			}			
		}
		&.dt-phone {
			thead {
				tr {
				    th {		    	
				    	flex: 1 0 31%;

					    &:nth-child(2) {
					   		flex: 0 0 105px;
					    }										    				    		        
				    }
				}
			}
			tbody {
			    tr {
				    td {
				    	flex: 1 0 31%;	   

				        &:nth-child(2) {
				        	flex: 0 0 105px;
				        }	
				    	.phone-img {
				    		img {
				    			width: 60px;
				    		}
						}
						.phone-overview {
							padding: 0px 3px;
							.item {
								font-size: 12px;
							}							
						}	
						.btn-sm {
							margin: 5px;
							padding-top: 12px;
							padding-bottom: 12px;
							border-radius: 6px;
							border: 1px #aaa solid;
							color: #aaa;
						}													        				        														 
				    } 
			    }
			}			
		}		
	}
}