/**
	
	HINWEIS:
	- In dieser Datei werden Änderungen zu allen "Datatable" Tabellen vorgenommen, die mit dem Klassennamen ".dt-phone" versehen werden
	- Hier werden üblicherweise Smartphone-Tarife in tabellenform formatiert

 */

table {	
	&.dt-phone {
		border: 0;
		line-height: 24px;
		font-size: 14px;
		color: #0E3A67;
		thead {
			tr {
			    th {
					height: 42px;
					border: 0;
					color: #ffffff;
					text-transform: uppercase;
					font-size: 16px;
					vertical-align: middle;		
					text-align: center;

					&:first-child { 
						border-top-left-radius: 3px;
						border-bottom-left-radius: 3px;
					}	
					&:last-child {
						border-top-right-radius: 3px;
						border-bottom-right-radius: 3px;
					}	
					&:nth-child(4) {
						cursor: text;
						position: relative;										
						&:after {
							visibility: hidden;
						}
					}		
					&:nth-child(3) {
						cursor: text;
						position: relative;
						&:after {
							visibility: hidden;
						}
					}														        
			    }
			}
		}
		tbody {
		    tr {
				&:nth-child(n) {
					background: #ffffff;
				}
				&:nth-child(2n) {
					background: #EEE;
				}
			    td {
			    	border: 1px @tarif-border-color solid;
					text-align: center;
					vertical-align: middle;
					background: transparent;
					
					&:nth-child(1) {
					   	width: 120px;
					}
					&:nth-child(2) {
					   	width: 155px;
					}		
					&:nth-child(3) {
					   	width: 170px;
					}	
					&:nth-child(4) {
					   	width: 220px;
					}
					&:nth-child(5) {
					   	width: 250px;
					}	
					&:nth-child(6) {
					   	width: 80px;
					}																										
					&:first-child {
						border-left: none;
					}
					&:last-child {
						border-right: none;
					}
			    	img {
			    		display: inline-block;
			    	}
			    	.duration { 
			    		strong {
							font-size: 30px;
							font-weight: @weight-semi-bold;
							color: @primary-color;
							display: block;
							line-height: 34px;
						}
						span {
							font-size: 14px;
							font-weight: @weight-normal;
							line-height: 13px;
						}
					}
					.charge {
						strong {
							font-size: 30px;
							font-weight: @weight-semi-bold;
							color: @primary-color;
						}
						span {
							font-size: 14px;
							font-weight: @weight-semi-bold;
							color: #2C8CAB;
						}
						hr {
							max-width: 95%;
							border-color: #bfbfbf;
							margin-top: 5px;
							margin-bottom: 5px;
						}	
					}
					.benefits {
						width: 95%;
						margin: 0 auto;
						min-width: 130px;

						.item {
							padding-top: 5px;
							padding-bottom: 5px;
							border-bottom: 1px @tarif-border-color solid;

							&:last-child {
								border: 0;
							}
							.item-left {
								width: 1px;
								min-height: 10px;
								float: left;
								text-align: left;
								white-space: nowrap;
								z-index: 10;
							}
							.item-right {
								width: 100px;
								min-height: 10px;
								float: right;
								text-align: right;
								white-space: nowrap;
								z-index: 9;	

								.fa-check {
									color: #70AB1A;
								}
								.fa-times {
									color: #EF0707;
								}
							}
						}	
					}
					.specials {
						clear: both;

						div {
							min-width: 200px;
							min-height: 12px;
							margin-top: 2px;
							margin-bottom: 2px;
							text-align: left;
							padding-left: 30px;	
							&:before {
								font-family: FontAwesome;
						   		content: "\f067";
						   		display: inline-block;
						   		vertical-align: middle;
						   		padding-right: 10px;
						   		margin-left: -25px;		
						   		color: @primary-color;
							}	
						}
					}	
			    	.phone-img {
			    		img {
			    			width: 100px;
			    			height: auto;
			    			margin-bottom: 2px;
			    		}
			    		font-weight: @weight-bold;
			    		
					}
					.phone-overview {

						margin-top: 5px;
						.item {
							padding-top: 3px;
							padding-bottom: 3px;
							border-bottom: 1px @tarif-border-color solid;
							font-size: 13px;

							&:last-child {
								border: 0;
							}
							.item-left {
								width: 1px;
								min-height: 10px;
								float: left;
								text-align: left;
								white-space: nowrap;
								z-index: 10;
							}
							.item-right {
								width: 90px;
								min-height: 10px;
								float: right;
								text-align: right;
								white-space: nowrap;
								z-index: 9;	
							}
						}							
					}														
			    } 
		    }
		}			
	}
}
