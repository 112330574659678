.top-bar {
  background: #eee;
  padding-top: 7px;
  padding-bottom: 7px;
  a {
    color: #333;
  }
}
.collapsing {
  transition: none;
}
nav {
  &.navbar {
    margin-bottom: 0px !important;
    z-index: 1001 !important;
  }
  .container {
    .navbar-header {
      .navbar-toggle {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      a.logo {
        margin-left: 0px;
        margin-top: 10px;
        padding-right: 20px;
        border-right: 1px #ffffff solid;
        float: left;
      }
    }
    ul.navbar-nav {
      li.dropdown {
        > a {
          font-size: 16px;
          color: #fff !important;
          padding-left: 20px;
          padding-right: 20px;
          text-transform: uppercase;
          padding-top: 18px;
          padding-bottom: 18px;
          &.dropdown-toggle {
            display: none;
          }
        }
        &.open {
          > a {
            color: @primary-color;
            background: #eee;
            background-image: linear-gradient(to bottom, #eee 0%, #eee 100%);
          }
        }
        ul.dropdown-menu {
          padding: 0;
          background: #fff;
          margin-top: -0.1px;
          box-shadow: none;
          li {
            padding: 0px;
            a {
              padding: 7px 20px;
              color: #555555;
            }
            &:hover {
              background: #eee;
            }
          }
        }
      }
    }
  }
}
