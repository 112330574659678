@family: "Open Sans";
@color: #3e4c51;

@tarif-border-color: #dddddd;

@weight-normal: 400;
@weight-semi-bold: 600;
@weight-bold: 700;

@primary-color: #9f294a;
@secondary-color: #f3fafc;

@gradient-from: #902642;
@gradient-to: #ae2d52;

@primary-button-color: #a82c4f;
@primary-button-color-hover: #952744;

@primary-header-color: #3e4c51;

.gradient {
  color: #ffffff;
  background: @gradient-from;
  background-image: linear-gradient(
    to top,
    @gradient-from 0,
    @gradient-to 100%
  );
}
.gradient-gray {
  color: #ffffff;
  background: #999;
}

.font-bold {
  font-weight: @weight-bold;
}
.collapse-filter {
  height: auto !important;
}
.clear {
  clear: both;
}
.popover-options {
  cursor: pointer;
}
.tooltip {
  white-space: normal;
  font-weight: @weight-normal;
}
.tooltip-inner {
  min-width: 240px !important;
  padding: 5px !important;
}
.v-line {
  width: 100%;
  height: 3px;
  margin-bottom: 25px;
  background: url(../img/vertical-line.png) no-repeat;
}
.image-left {
  float: left;
  margin-right: 15px;
  margin-bottom: 5px;
}
button:focus {
  outline: 0 !important;
}
.dataTables_empty {
  font-weight: 700;
  font-size: 22px;
  padding: 20px 0px !important;
}
.dataTables_filter {
  display: none;
}
.tooltip {
  z-index: 50000 !important;
}

.bc {
  margin-bottom: 10px;
  a {
    color: #333;
  }
}
.carousel {
  margin-top: 20px;
  .carousel-inner {
    img {
      margin: auto;
      margin-top: 80px;
      &.border {
        border: 1px #ccc solid;
      }
    }
  }
  .item {
  }
  .carousel-caption {
    top: -100px;
    text-shadow: none;
    color: #3e4c51;
    padding-top: 0px;
    h3 {
      margin-top: 25px;
      font-size: 18px;
    }
  }
  .carousel-indicators {
    top: 45px;
    li {
      background: #ccc;
      border: none;
      &.active {
        background: #3e4c51;
        border: none;
      }
    }
  }
  .carousel-control {
    opacity: 1;
    color: #3e4c51;
    text-shadow: none;
    background: transparent;
    top: 55%;
    bottom: auto;
  }
}
@import "buttons.less";
@import "filter.less";
@import "tables.less";
@import "tables-dt.less";
@import "tables-dt-phone.less";
@import "tables-responsive.less";
@import "panels.less";
@import "navigation.less";
@import "popover.less";
@import "modules.less";
@import "modal.less";
@import "responsive.less";
@import "misc.less";

.mt-xs {
  margin-top: 5px !important;
}
.mb-xs {
  margin-bottom: 5px !important;
}
.mr-xs {
  margin-right: 5px !important;
}
.ml-xs {
  margin-left: 5px !important;
}

.mt-sm {
  margin-top: 10px !important;
}
.mb-sm {
  margin-bottom: 10px !important;
}
.mr-sm {
  margin-right: 10px !important;
}
.ml-sm {
  margin-left: 10px !important;
}

.mt-md {
  margin-top: 20px !important;
}
.mb-md {
  margin-bottom: 20px !important;
}
.mr-md {
  margin-right: 20px !important;
}
.ml-md {
  margin-left: 20px !important;
}

.mt-lg {
  margin-top: 40px !important;
}
.mb-lg {
  margin-bottom: 40px !important;
}
.mr-lg {
  margin-right: 40px !important;
}
.ml-lg {
  margin-left: 40px !important;
}

.mt-none {
  margin-top: 0px !important;
}
.mb-none {
  margin-bottom: 0px !important;
}
.mr-none {
  margin-right: 0px !important;
}
.ml-none {
  margin-left: 0px !important;
}

.pt-xs {
  padding-top: 5px !important;
}
.pb-xs {
  padding-bottom: 5px !important;
}
.pr-xs {
  padding-right: 5px !important;
}
.pl-xs {
  padding-left: 5px !important;
}

.pt-sm {
  padding-top: 10px !important;
}
.pb-sm {
  padding-bottom: 10px !important;
}
.pr-sm {
  padding-right: 10px !important;
}
.pl-sm {
  padding-left: 10px !important;
}

.pt-md {
  padding-top: 20px !important;
}
.pb-md {
  padding-bottom: 20px !important;
}
.pr-md {
  padding-right: 20px !important;
}
.pl-md {
  padding-left: 20px !important;
}

.pt-lg {
  padding-top: 40px !important;
}
.pb-lg {
  padding-bottom: 40px !important;
}
.pr-lg {
  padding-right: 40px !important;
}
.pl-lg {
  padding-left: 40px !important;
}

.pt-none {
  padding-top: 0px !important;
}
.pb-none {
  padding-bottom: 0px !important;
}
.pr-none {
  padding-right: 0px !important;
}
.pl-none {
  padding-left: 0px !important;
}

body {
  background: #eee;
  margin: 0;
  padding: 0;
  font-family: @family, sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: @color;
  min-height: 100%;
  height: 100%;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3/2),
  only screen and (min--moz-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
}

/*******
HEADINGS
*******/
h1 {
  font-size: 26px;
  font-weight: 700;
  color: @primary-header-color;
}
h2 {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 700;
  color: @primary-header-color;
  text-align: left;
}
h3 {
  font-size: 16px;
  font-weight: 700;
  color: @primary-header-color;
}
h4 {
  font-size: 16px;
  font-weight: 700;
  color: @primary-header-color;
}
/*
.scrolltop {
	display:none;
	width:100%;
	margin:0 auto;
	position:fixed;
	bottom:20px;
	right:10px;	

	.scroll {
		position: absolute;
		right: 20px;
		bottom: 20px;
		background: #b2b2b2;
		background: rgba(178,178,178,0.7);
		padding: 20px;
		text-align: center;
		margin:  0 0 0 0;
		cursor: pointer;
		transition: 0.5s;	
		
		&:hover {
			background: rgba(178,178,178,1.0);
			transition: 0.5s;
		}
		&:hover .fa {
			padding-top: -10px;
		}
		.fa {
			font-size: 30px;
			margin-top: -5px;
			margin-left: 1px;
			transition: 0.5s;
			color: #333333;
		}
	}
}
*/
ul {
  &.filter-tabs {
    border: 0;

    > li a {
      border: 2px @primary-color solid !important;
      margin-right: 3px;
      color: @primary-color;
      cursor: pointer;
      font-size: 20px;
      border-radius: 0;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        color: #ffffff !important;
        background: @primary-color;
      }
    }
    > li.active {
      a {
        color: #ffffff !important;
        background: @primary-color;

        &:hover {
          color: #ffffff !important;
          background: @primary-color;
        }
      }
    }
  }
}

.overlay-loading {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
  z-index: 10000;
  transition: all 1s;

  .spinner {
    top: 50%;
    left: 50%;
    margin-top: -20px;
    margin-left: -25px;
    position: absolute;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;

    div {
      background-color: #111;
      height: 100%;
      width: 6px;
      display: inline-block;
      animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }
    .rect2 {
      animation-delay: -1.1s;
    }
    .rect3 {
      animation-delay: -1s;
    }
    .rect4 {
      animation-delay: -0.9s;
    }
    .rect5 {
      animation-delay: -0.8s;
    }
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}

.spinner2 {
  margin: 0 auto;
  width: 70px;
  text-align: center;
}
.spinner2 > div {
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner2 .bounce1 {
  animation-delay: -0.32s;
}

.spinner2 .bounce2 {
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.c-radio span {
  border-radius: 500px !important;
}
.c-checkbox,
.c-radio {
  margin: 0;
  * {
    cursor: pointer;
  }
  label {
    margin: 0;
  }
  input {
    opacity: 0;
    position: absolute;
    margin-left: 0 !important;
    margin: 0;
  }
  span.fa {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 15px;
    height: 15px;
    border-radius: 2px;
    border: 1px solid #aaa;
    margin-right: 5px;
    margin-top: 3px;
  }
  &:hover span.fa {
    border-color: @primary-color;
  }
  span.fa:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    text-align: center !important;
    font-size: 8px;
    line-height: 13px;
    vertical-align: middle;
  }
  input[type="checkbox"]:checked + span.fa:before,
  input[type="radio"]:checked + span.fa:before {
    color: @primary-color;
    opacity: 1;
    transition: color 0.3 ease-out;
  }
  input[type="checkbox"]:checked + span.fa,
  input[type="radio"]:checked + span.fa {
    border-color: @primary-color;
    background-color: #fff;
  }
  input[type="radio"]:checked + span.fa {
    background-color: #fff;
  }
  input[type="radio"]:checked + span.fa:before {
    color: @primary-color;
  }
  input[type="checkbox"]:disabled + span.fa,
  input[type="radio"]:disabled + span.fa {
    border-color: #dddddd !important;
    background-color: #dddddd !important;
  }
  input[type="radio"]:disabled + span.fa {
    background-color: #fff !important;
  }
  input[type="radio"]:disabled + span.fa:before {
    color: #dddddd;
  }
  input[type="checkbox"] + span.fa + span,
  input[type="radio"] + span.fa + span {
    font-weight: @weight-normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    padding-right: 10px;
  }
  input[type="radio"] + span.fa + span:not(.allow-overflow) {
    width: 200px;
  }
  input[type="checkbox"]:checked + span.fa + span,
  input[type="radio"]:checked + span.fa + span {
    color: @primary-color;
  }
}
.rating {
  display: inline-block;
  float: left;
  unicode-bidi: bidi-override;
  direction: rtl;
  height: 30px;
  margin-top: 15px;
  margin-left: -10px;
  margin-bottom: 10px;
  input {
    position: absolute;
    left: -999999px;
  }
  label {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-left: 10px;
    font-size: 0;
    height: 30px;
    opacity: 0.5;
    &:before {
      position: relative;
      font: 30px/1 FontAwesome;
      display: block;
      content: "\f005";
      color: #bbb;
      transition: all 0.2s;
      cursor: pointer;
    }
    &.selected:before,
    &.selected ~ label:before {
      color: @primary-color;
    }
  }
  &:not(.already) {
    label {
      opacity: 1;
      &:hover:before,
      &:hover ~ label:before {
        color: @primary-color;
      }
    }
  }
}
.rating-schema {
  span {
    font-weight: @weight-bold;
    margin-top: 8px;
    display: inline-block;
  }
  img {
    float: left;
    width: 60px;
    height: auto;
    margin-right: 15px;
  }
  div {
    span {
      display: inline;
    }
  }
}
.compare {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 0px;
  background: rgba(0, 0, 0, 0.8);

  .container {
    ul {
      margin: 0;
      margin-top: 2px;

      li {
        padding: 0px 10px;
        position: relative;

        span i {
          right: 5px;
          top: -8px;
          position: absolute;
          cursor: pointer;
          font-size: 16px;
          color: #fff;
        }
      }
    }
    .title {
      color: #fff;
      font-size: 20px;
      text-transform: uppercase;
      line-height: 47px;
    }
  }
  .btn {
    text-transform: uppercase;
  }
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;

  & > [class^="col-"],
  & > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.row.xs-gutters {
  margin-right: 0;
  margin-left: 0;

  & > [class^="col-"],
  & > [class*=" col-"] {
    padding-right: 3px;
    padding-left: 3px;
  }
}

/*******
SCAFFOLD
*******/

.container {
  max-width: 1200px !important;
}
.content {
  padding: 30px 40px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-top: 0;
  border-radius: 2px;
  > section {
    padding-bottom: 20px;
  }
}
footer {
  padding: 20px 0px;
  color: #333333;
  i {
    color: @primary-color;
  }
}
