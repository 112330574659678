/**
	
	HINWEIS:
	- In dieser Datei werden Änderungen zu allen "allgemeinen" Tabellen vorgenommen, die nicht durch spezielle Klassennamen deklariert werden. 
	- Hier wird lediglich der Standard-Bootsstrap-Style überschrieben (Klassenname: ".table")

 */

table {
  &.white tbody tr td {
    background: #ffffff !important;
  }
  .td-h-left {
    text-align: left !important;
  }
  .td-h-center {
    text-align: center !important;
  }
  .td-h-right {
    text-align: right !important;
  }

  .td-v-top {
    vertical-align: top !important;
  }
  .td-v-center {
    vertical-align: middle !important;
  }
  .td-v-bottom {
    vertical-align: bottom !important;
  }

  &.table-left {
    tr td {
      text-align: left !important;
      vertical-align: top !important;
    }
  }
  &.table-center {
    tr td {
      text-align: center !important;
    }
  }
  &.table {
    border: 0;
    thead {
      tr {
        th {
          .gradient;

          height: 42px;
          border: 0;
          text-transform: uppercase;
          font-weight: @weight-normal;
          font-size: 14px;
          vertical-align: middle;
          text-align: center;

          img {
            margin: 0;
            margin-bottom: 5px;
          }
          &:first-child {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }
          &:last-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          border: 1px @tarif-border-color solid;
          text-align: center;
          vertical-align: middle;
          background: #eee;

          img {
            margin: 0;
          }
          &:first-child {
            border-left: none;
          }
          &:last-child {
            border-right: none;
          }
          ul {
            margin-left: -25px;
            li {
              margin-bottom: 3px;
            }
          }
          .hl {
            color: @primary-color;
          }

          .benefits {
            width: 95%;
            margin: 0 auto;
            min-width: 130px;

            a {
              color: #0e3a67;
              text-decoration: none;
            }

            .item {
              padding-top: 5px;
              padding-bottom: 5px;
              border-bottom: 1px @tarif-border-color solid;

              &:last-child {
                border: 0;
              }
              .item-left {
                width: 1px;
                min-height: 10px;
                float: left;
                text-align: left;
                white-space: nowrap;
                z-index: 10;
              }
              .item-right {
                width: 100px;
                min-height: 10px;
                float: right;
                text-align: right;
                white-space: nowrap;
                z-index: 9;

                .fa-check {
                  color: #70ab1a;
                }
                .fa-times {
                  color: #808080;
                }
              }
            }
          }
        }
      }
    }
  }
}
.modal .table .benefits {
}
