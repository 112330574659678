.btn {
    &.btn-gradient {
	
        text-shadow: none;
    	background: @primary-button-color;
        border-color: @primary-button-color-hover;
        color:#ffffff;
    	font-size: 20px;
    	padding-left: 20px;
    	padding-right: 20px;
        transition: background-color 0.3s, color 0.3s;	
        white-space: normal;

        &:focus,
        &:hover {

        	color:#fef9f9;
            background-color: @primary-button-color-hover;
        }
        &:active,
        &.active {

        	color:#fef9f9;
            background-color: @primary-button-color-hover;
        }
        &.disabled,
        &:disabled,
        &[disabled] {

            background-color: #e0e0e0;
            background-image: none;
        }
    }
}
.btn-blue {
    background: #218DB9;
}

.btn-light-blue {
    
    text-shadow: none;
    background:#FFFFFF;
    border-color: #DDDDDD;
    color: @primary-color;
    transition: background-color 0.3s, color 0.3s;  
    height:70px;
    font-size:18px;

    &:focus {
        background: #FFFFFF;
        border-color: #DDDDDD;
        color: @primary-color;
    }
    &:hover {
        color: #333333;
        background-color: #F8F8F8;
    }
    &:active,
    &.active {
        background-color: #f5f5f5;
        border-color: none;
    }
    &.disabled,
    &:disabled,
    &[disabled] {
        background-color: #e0e0e0;
        background-image: none;
    }
    &:hover .cust-checkbox span {
        border-color: @primary-color;
    }
    .btn-inner {
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
    }
    .btn-inner-radio {
        align-self: center;
        text-align: left;
        position: relative;
        flex: 0 0 35px;
        padding: 0 2%;
    }
    .btn-inner-description {
        flex: 1 1 auto;
        align-self: center;
        text-align: center;
        padding-right: 15px;
        font-size: 18px;
    }
    .btn-inner-description-hint {
        flex: 1 1 auto;
        align-self: center;
        text-align: center;
        padding-right: 15px;
        font-size: 17px;
        text-align: left;
    }
    .btn-inner-description-hint small {
        font-size: 13px;
        font-weight: normal !important;
        color: #0E3A67;
    }
    .btn-inner-icon {
        flex: 1 1 auto;
        align-self: center;
        text-align: center;
        padding-right: 5%;   
    }
    .btn-inner-hint {
        flex: 1 1 auto;
        align-self: center;
        text-align: center;
        padding-right: 15px;
        font-size: 11px;
    }
}
.btn-filter, 
.reset-form {
    height: 40px;
    font-size: 17px;
    font-weight: @weight-bold;
    margin-bottom: 20px;
}
.btn-fake {
    background:#FFFFFF;
    border: 1px #DDDDDD solid;
    border-radius: 4px;
    
    .btn-light-blue {
        margin: 0;
        padding: 0px 12px;
        border-color: transparent;
        background: transparent;
    }
}