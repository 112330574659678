nav {
	.logo {
		margin-left: 10px;
		margin-bottom: 10px;
	}			
}	
@media (min-width: 768px) {
	ul.nav li.dropdown:hover > ul.dropdown-menu {
	    display: block;    
	}
}

@media (max-width:1199px) {
	.img-responsive-md {
		width:100%;
	}		
	nav {
		.container {
			.navbar-header {
				margin: 0 auto;
				float: none;
				display: block;
				text-align: center;
				a.logo {
					display: inline-block;
					margin-top: 15px;
					margin-bottom: 0px;
					margin-left: 20px;
					border: none;
					img {
						width: 220px;
					}	
				}
			}
			ul.navbar-nav {
				li.dropdown {
					> a  {
						padding-left: 20px;
						padding-right: 20px;
						margin: 0;
						font-size: 16px;
					}
				}
			}
		}
	}
}
@media (max-width:991px) {
	.modal {
		.modal-dialog {
			.modal-content {
				.modal-body {
					h1 {
						margin-top: 0px;
					}
				  	.table {
				  		max-width: 100% !important;
				  		min-width: 0;

				  		tr {
				  			th.gradient {
								color: #333;
								background: transparent; 
								text-align: left;
								font-weight: 700;
								font-size: 16px;
							}
				  		}
				  	}
				}				
			}
		}
	}

	.img-responsive-sm {
		width:100%;
	}	
	.modal-xl {

		width: auto;
	}
	nav {
		.container {
			.navbar-header {
				margin: 0 auto;
				float: none;
				display: block;
				text-align: center;
				a.logo {
					display: inline-block;
					margin-top: 15px;
					margin-bottom: 0px;
					border: none;
					float: none;
					img {
						width: 220px;
					}	
				}
			}
			ul.navbar-nav {
				li.dropdown {
					> a  {
						padding-left: 13px;
						padding-right: 13px;
						margin: 0;
						font-size: 15px;
					}
				}
			}
		}
	}
	.intro h1 {

		text-align: left !important;
		font-size: 17px !important;
	}
}
/*********
SMARTPHONE
*********/
@media (max-width:767px) {

	body.modal-open {
		position: fixed;
		overflow: hidden;
	}	
	.modal {
		.modal-dialog {
		  height: 100%;
		  padding: 0;
		  margin: 5px;
		  margin-bottom: 0px;

			.modal-content {
			  	height: 99%;
			  	position: relative;		
		 		border-bottom-left-radius: 0px !important;
		  		border-bottom-right-radius: 0px !important;
		  		border-bottom: none !important;

				.modal-header {
					border-bottom: 1px #eee solid;
					padding-bottom: 10px;
					height: 65px;
				  	position: absolute;
			  		width: 100%;	

			  		h4.modal-title {
			  			height: 30px;
			  		}		
			  		.shadow-bottom {
			  			width: 100%;
			  			height: 15px;
			  			bottom: 0px;
			  			left: 0px;
			  			right: 0px;
			  			position: absolute;
			  			z-index: 100;
			  			margin-bottom: -15px;
			  			background: linear-gradient(to top, rgba(0,0,0,0), rgba(100,100,100,0.8));
			  		}			  			
				}
				.modal-body {
			  		position: absolute;
			  		top: 65px;
			  		bottom: 0px;
			  		width: 100%;
			  		overflow-y: auto;
			  		padding: 10px 5px;

					.row {
						margin-left: 0px !important;
						margin-right: 0px !important;
						div[class*="col-"]  {
						    padding-left: 0px !important;
						    padding-right: 0px !important;	    					
						}			
					}	
				  	.table {
				  		max-width: 100% !important;
				  		min-width: 0;

				  		tr {
				  			th.gradient {
								color: #333;
								background: transparent; 
								text-align: left;
								font-weight: 700;
								font-size: 16px;
				  			}
				  		}
				  	}
				}			
			}
		}
	}	
	.img-responsive-xs {
		width: 100%;
	}	
	.v-line { 
		margin-bottom: 10px; 
	}		
	h1 { 
		margin-top: 5px !important; 
		text-align: left !important; 
	}
	.btn {
		&.btn-gradient {
			padding:10px !important;
			font-size: 14px;
			display: block;
		}
	}
	nav {
		.container {
			.navbar-header {
				.navbar-toggle {
					border: 0; 
					margin-right: 0;
					margin-top: -3px;
					margin-bottom: 2px;
					&:hover,
					&:focus,
					&:active {
						background:transparent !important;	
					}
					.icon-bar {
						background-color: #fff !important;
						margin: 0 auto;
						margin-top: 4px;
						&:first-child {
							margin-top: 5px;
						}
					}					
				}
				a.logo {
					float: left;
					border: none;
					margin-top: 12px;
					margin-left: 0;
					padding-right: 0;
				}
			}
			.navbar-collapse {
				border: none;
			}
			ul.navbar-nav {
				padding: 15px 15px;
				margin-top: 0px;
				margin-bottom: 0px;
				li.dropdown {
					border-bottom: 1px #d26e8d dotted;
					&:last-child {
						border: none;
					}
					> a  {
						padding: 0;
						padding-top: 15px;
						padding-bottom: 15px;
						padding-right: 10px;
						display: inline-block;
						font-size: 17px;
						text-transform: none;
						background: transparent;
						&:hover {
							text-decoration: underline;
						}
						&.dropdown-info {
							width: auto;
						}
						&.dropdown-toggle {
							width: 38%;
							float: right;
							text-align: right;
							padding-right: 14px;
							padding-left: 0;
							font-size: 15px;
							display: inline-block;
						}
						b {
							display: none;
						}
					}
					&.open {
						> a {
							color: #fff;
							background: transparent !important;
						}				
					}
					ul.dropdown-menu { 
						background: #fff;
						margin-top: 0;
						margin-left: -15px;
						margin-right: -15px;
						padding: 0px 15px;
						border-radius: 0px;
						li {
							padding: 0;
							a {
								padding: 10px 0px;
								border-bottom: 1px #eee dotted;
							}
							&:last-child a {
								border: none;
							}
							&:hover {
								text-decoration: underline;
								background: transparent;
							}
						}
					}
				}	
			}	
		}
	}
	.filter { 
		padding:5px; 

		.slider { 
			margin: 40px 15px; 
			margin-bottom: 10px; 
		}
		.filter-divider .line { 
			width: 50%; 
		}
		.slider-value {
			text-overflow: ellipsis;
			width: 300px;
			white-space: nowrap;
			overflow: hidden;	
		}
		.btn-light-blue {
			.btn-inner-description { 
				font-size:15px !important; 
			}
			.btn-inner-radio {          
				flex: 0 1 40px;  		
				width: 20%;
			}
		}
		.elem {
			padding-top:2px;
			padding-bottom:2px;
		}			
	}	

	main.content {
		padding: 10px 15px !important;

		/*
		section .row:not(.xs-gutters) {
			margin-left: 0px !important;
			margin-right: 0px !important;
			div[class*="col-"]  {
			    padding-left: 0px !important;
			    padding-right: 0px !important;	
			}		
		}	
		*/
		.row:not(.xs-gutters) {
			margin-left: 0px;
			margin-right: 0px;
			div[class*="col-"]  {
			    padding-left: 0px;
			    padding-right: 0px;	    					
			}			
		}	
		.row.xs-gutters {
			div[class*="col-"]  {
			    padding-left: 5px !important;
			    padding-right: 5px !important;	 			
			}
		}		
	}	
	.offers {
		.item { 
			flex: 1 1 51%; // hack to prevent ie 11 padding bug		
			padding: 10px 0px;
		}
	}
	.reviews {
		.item {
			.item-content {
				.item-content-right {
					padding: 0px 5px;
				}
			}
		}
	}
	.contact .contact-content {
		padding: 5px;
	}
	/*	
	.comment {
		.g-recaptcha {
			margin-left: -13px;
		}
	}
	*/
	.text {
		padding: 10px 0px;
	}	
	.blog-preview {
		.item { 
			padding: 0;
			.last {
				a {
					font-size: 16px;
				}			
			}
		}
	}	
	.table-p h1 { font-size:20px !important; text-align:left !important; margin:10px 0px !important; }
	.table-p h1 img { display: none; }
	.table-p h1 button { padding:5px 10px !important; margin-top:-5px; }
	.intro h1 span {
		display:block;
		margin-bottom:2px;
	}
	footer .text-right {
		padding-top:10px;
		text-align:left !important;
	}
	
	.no-more-tables {
		table, 
		thead, 
		tbody, 
		th, 
		td, 
		tr { 
			display: block !important; 
		}
		thead tr { 
			position: absolute !important; 
			top: -9999px !important; 
			left: -9999px !important; 
		}
		tr { border: 1px solid #ccc; }
		td { 
			border: none;
			border-bottom: 1px solid #eee; 
			position: relative;
			padding-left: 30% !important;  
			white-space: normal;
			text-align:left;
			min-height: 60px;

			&:before { 
				position: absolute !important; 
				top: 6px !important; 
				left: 6px !important; 
				width: 25%; 
				padding-right: 10px; 
				text-align:left;
				font-weight: bold;
				content: attr(data-title);
			}
		}
	}
}