.rating_bar {
  width: 150px;
  margin-bottom: 20px;
  background: url(../img/sterne/sternebewertung-leer.png) 0 0 repeat-x;
}

.rating_bar div {
  height: 27px;
  background: url(../img/sterne/sternebewertung-voll.png) 0 0 repeat-x;
}

.rating_bar_klein {
  margin-left: 20px;
  width: 77px;
  background: url(../img/sterne/sternebewertung-leer-klein.png) 0 0 repeat-x;
}

.rating_bar_klein div {
  height: 14px;
  background: url(../img/sterne/sternebewertung-voll-klein.png) 0 0 repeat-x;
}

.modal {
  .modal-dialog {
    .modal-content {
      .modal-body {
        .table {
          tr {
            th.gradient {
              color: #333;
              background: transparent;
              text-align: left;
              font-weight: 700;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}


.freeprivacypolicy-com---nb-interstitial-overlay {
  background: rgba(0, 0, 0, 0.9) !important;
}
@media screen and (min-width: 1140px) {
  .freeprivacypolicy-com---nb-interstitial {
    top: 10vh !important;
    left: 20vw !important;
    right: 20vw !important;
  }
}
@media screen and (min-width: 992px) {
  .freeprivacypolicy-com---nb-interstitial {
    top: 10vh !important;
    left: 15vw !important;
    right: 15vw !important;
  }
}
.cc-nb-buttons-container {
  display: flex !important;
  justify-content: flex-start !important;
}
.cc-nb-changep {
  order: 1 !important;
  margin-top: 6px !important;
  background-color: inherit !important;
}
.cc-nb-okagree {
  position: relative !important;
  order: 0 !important;
  font-size: 110% !important;
  padding: 16px 80px !important;
  margin-top: 2px;
}

.cc-nb-reject {
  display: none !important;
}
@media screen and (max-width: 576px) {
  .btn-block-xs-only {
    display: block;
    width: 100%;
  }
  .cc-nb-buttons-container {
    position: relative !important;
    flex-wrap: wrap !important;
  }
  .cc-nb-okagree {
    order: 1 !important;
    position: relative !important;
    width: 100% !important;
  }
  .cc-nb-changep {
    order: 0 !important;
    width: 100% !important;
  }
}
@media screen and (max-width: 768px) {
  .cc-nb-okagree {
    padding: 16px 60px !important;
  }
}
