/******************************
AUSZEICHNUNGEN
******************************/
.award {
	.box-grid {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;

		.box {
			flex: 0 0 25%;
			padding: 5px;
		}
	}
}
/******************************
ERROR: 404
******************************/
.e404 {
	min-height: 10px;
	margin-left: 50px;
	color: #555;
	font-size: 16px;
	line-height: 25px;

	h1 {
		color: @primary-color !important;
		line-height: 45px  !important;

		span {
			font-size: 45px;
		}			
	}
}
/******************************
EINZELSEITE BLANK
******************************/
.single {
	font-size:15px;
	line-height: 23px;
}
/******************************
KOMMENTARE
******************************/
.comment .stars-input { margin-top: 6px; }
.comment .stars-input i {
	margin-right: 6px;
	color: #FAD913;
	font-size: 23px;
	cursor: pointer;
}
/******************************
HILFECENTER
******************************/
.contact {
	min-height: 140px;
	line-height: 26px;
	font-size: 15px;
	color: #333;

	table {
	    width: 100%;
	}
	.contact-image {
		min-height: 140px;
		padding: 20px;
		text-align: center;
		background: @secondary-color;
	    width: 40%;
	    vertical-align: middle;
	}
	.contact-content {
		min-height: 140px;
		padding: 20px;
		background: #fff;
		text-align: left;
	    width: 60%;
	    vertical-align: middle;
	}
}
/******************************
ERFAHRUNGEN
******************************/
.experience {
	.item { 
		transition: background-color 0.5s;	
		display: flex;
		justify-content: flex-start;
		align-items: stretch;
		margin-bottom: 10px;

		.first {
			.gradient;
			padding: 10px;
			flex: 1 1 auto;
			flex-basis: calc(20% - 20px);			
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
		
			img {
				border: 1px solid #fff;
				background: #fff;
			}			
		}
		.last {
			flex: 1 1 auto;
			flex-basis: calc(80% - 30px);			
			background: @secondary-color;
			padding: 15px;
			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;

			i {
				margin-right: 6px;
				color: #FAD913;
				font-size: 23px;
			}	
			p {
				font-size: 15px;
				line-height: 26px;
			}	
		}
	}	
}
/******************************
DETAILBEREICH
******************************/
.text {
	color: #3e4c51; //color: #135771;
	line-height: 26px;
	font-size: 16px;
	text-align: left;

	h2 {
		margin: 25px 0px 8px 0px;
		font-size: 18px;
	}
	img {
		margin-right: 20px;
		margin-bottom: 20px;

		&.img-responsive-md,
		&.img-responsive-sm,
		img-responsive-xs {

			margin: 0;
		}
	}
	.filter {
		img {
			margin: 0;
		}	
	}	
}
/******************************
INTRO
******************************/
.intro {
	color: #3e4c51; //color: #135771;
	line-height: 26px;
	font-size: 16px;
	text-align: left;

	h1 { 
		font-size:22px; 
	}
	.intro-left .thumbnail {
		height: 150px;
		width: 150px;
		margin: 0 auto;
	}
}
/******************************
TESTBERICHTE
******************************/
.reviews .item {
	width: 100%;
	min-height: 10px;
}
.reviews .item-title {
	padding: 10px;
	border-radius: 3px;
	font-size: 16px;
	color: #fff;
}
.reviews .item-title a {
	color: #fff;
}
.reviews .item-title img {
	background-color:#fff;
	padding: 3px;
	margin-right:10px;
	height:50px;
	width:100px;
}
.reviews .item-content {
	width: 100%;
	min-height: 10px;
	padding: 20px;
	background: @secondary-color;
	line-height: 23px;
	font-size: 15px;
	color: #135771;
}
.reviews .item-content-right { text-align: justify; }
.reviews .item-content-right h2 {
	margin:10px 0px;
}
.reviews .item-content-right ul { margin: 0; }
.reviews .item-content-right .form-button {
	display: inline-block;
	text-align: center;
	margin: 5px 0 10px;
	color: #fff;
}
/******************************
ALLNET-FLAT-ANGEBOTE [startseite]
******************************/
.offers {
	padding-top: 20px;
	padding-bottom: 20px;
	display: flex;
	flex-wrap: wrap;
	.item { 
		flex: 1 0 35%; // hack to prevent ie 11 padding bug
		padding: 10px 25px;
		border-radius: 5px;
		line-height: 26px;
		font-size: 15px;
		text-align: left;
		h2 {
			margin: 10px 0px;
			font-size: 18px;
			font-weight: @weight-semi-bold;
			a {
				color: @primary-header-color;
				text-decoration: none;
			}
		}
	}
}
/******************************
BLOG PREVIEW
******************************/
.blog-preview {
	a.list-group-item-small {
		color: #555 !important;
	}
	.item { 
		padding: 10px 0px;
		border-bottom: 1px #dddddd solid;
		transition: background-color 0.5s;	
		display: flex;
		justify-content: flex-start;
		align-items: center;
		
		&:last-child { 
			border: 0; 
		}

		.first {
			width: 125px;
			flex: 0 0 125px;
			.thumbnail {
				border: 1px #dddddd solid;
				margin: 0;
			}			
		}
		.last {
			flex: 1 1 auto;
			a {
				font-size: 18px;
				color: @primary-header-color;
			}			
		}
	}
}
.page-overview-grid {
	margin-bottom: 10px;
	color: #3e4c51;
	line-height: 26px;
	font-size: 16px;
	text-align: left;
	strong {
		display: block;
		margin-bottom: 6px;
	}	
	a {
		line-height: 23px;
	}
	ul {
		margin-left: -20px;
		margin-bottom: 6px;
	}				
}
.page-overview {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 10px;
	color: #3e4c51;
	line-height: 26px;
	font-size: 16px;
	text-align: left;
	strong {
		display: block;
		margin-bottom: 6px;
	}	
	.first {
		flex: 1 0 30%;	
		text-align: center;
	}
	.last {
		flex: 1 0 70%;	
		a {
			line-height: 23px;
		}
		ul {
			margin-left: -20px;
			margin-bottom: 0px;
		}				
	}
}
/******************************
WEITERE INHALTE
******************************/
.more {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	
	.first {
		flex: 1 0 30%;	
		text-align: center;

		i {
			color: #dddddd;
		}
	}
	.last {
		flex: 1 0 70%;		
		ul {
			margin-left: -40px;
		}
		a {
			color: @primary-color;
		}
	}
}

/******************************
TARIFFINDER
******************************/
.finder {
	.nav.nav-pills {
		> li {
			> a {
				color: @primary-color;
			}
			&.active > a {

				background: @primary-color;
				color: #fff;
			}
			&.disabled {
				a {
					color: #ccc;
				}
			}			
		}
	}
	.tab-content {
		.tab-pane {
			label.title {
				color: @primary-color;
				font-weight: @weight-bold;
				font-size: 16px;
			}
			.slider {
				margin: 20px !important;
				.noUi-pips {
					color: #0E3A67;
				}				
			}
			.btn {
				padding: 6px 12px;
				font-size: 16px;
			}
		}
	}
}

/******************************
VIDEO
******************************/
.video iframe {
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 2px;
}

/******************************
PLAN CATEGORIES TOP
******************************/
.plan-categories-top {
	
}
.trust {
	.flex {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		.first {
			flex: 1 0 120px;	
		}
		.last {
			flex: 1 0 65%;				
		}
	}
}