.panel-sidebar {
	border-color: #dddddd;
	font-size: 15px;
	line-height: 23px;
	
	.panel-heading {
		font-size: 15px;
		color: #ffffff !important;
		text-transform: uppercase;
	}
	.panel-body {
		.header-icon {

			width: 35px;
			height: auto;
			margin-right: 5px;
		}
		.flex {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			.first {
				flex: 1 0 20%;	
			}
			.last {
				flex: 1 0 80%;				
			}
		}
		h2 a {
			color: @primary-header-color;
			font-weight: 400;
		}
		ul {
			margin: 0 0 0 -25px;

			li {
				list-style: square;
				margin-bottom: 5px;
				a {
					color: #135771;
					font-size: 18px;
				}
			}
		}
	}
	> .list-group .list-group-item {
		border-width: 1px 0;

		&:first-child {
		    border-top-right-radius: 0;
		    border-top-left-radius: 0;
		}
		&:last-child {
		    border-bottom: 0;
		}
	}
}
.panel-default .panel-body {

	line-height:23px;
}
.panel-default .panel-heading {

	font-size: 15px;
	
	color: #fff!important;
	text-transform: uppercase;
}
